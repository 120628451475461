<template>
    <v-form>
        <v-container>
            <div v-if="!isSending && !isLoading">
                <v-row class="mt-4" justify="center">
                    <v-col cols="12" md="12">
                        <v-btn block @click="goBack" color="secondary">
                            Terug
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="6" v-if="false">
                        <v-btn block @click="goToNew" color="primary">
                            Naar nieuwe versie
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <div class="display-1 mt-4">
                        Gegevens rapport
                    </div>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field id="input-temp-name" outlined label="Tijdelijke naam" v-model="form.type"
                            hide-details>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-combobox id="input-klant" outlined :items="customers.map(user => user.naam)" label="Klant"
                            v-model="form.klant" required :rules="[rules.required, rules.isKlant]">
                        </v-combobox>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field id="input-2" outlined label="Opnamedatum" v-model="form.datum_taxatie" required
                            hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" md="8">
                        <v-text-field id="input-3" outlined label="Doel taxatie" v-model="form.doel_taxatie" required
                            hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field id="input-4" outlined label="Tijd aanvang" v-model="form.tijd_aanvang" required
                            hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field id="input-5" outlined label="Tijd vertrek" v-model="form.tijd_vertrek" required
                            hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field id="input-6" outlined label="Dossiernummer" v-model="form.dossiernummer" required
                            hide-details>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field id="input-rapportnummer" outlined label="Rapportnummer / Datum"
                            v-model="form.rapportnummer" hide-details></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <div class="display-1">Gegevens voertuig</div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field id="input-10" outlined label="Chassisnummer" v-model="form.chassisnummer"
                            :rules="[rules.required]" counter maxlength="17"
                            @change="() => (form.chassisnummer = form.chassisnummer.toUpperCase())"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field id="input-kleur" outlined
                            label="Kleur (wordt automatisch gevuld met N.V.T. als niet ingevuld)" v-model="form.kleur"
                            hide-details></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field outlined label="Handelswaarde" v-model="form.handelswaarde"
                            hide-details></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field outlined label="schadebedrag" v-model="form.schade_bedrag"
                            hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <span class="title">Is de auto al rdw gekeurd?</span>
                        <v-radio-group id="radio-rdw-gekeurd" v-model="rdw_gekeurd" row>
                            <v-radio value="ja" label="Ja"> </v-radio>
                            <v-radio value="nee" label="Nee"></v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-col cols="12" v-if="rdw_gekeurd === 'ja'">
                        <v-text-field id="input-rdw-gekeurd" outlined label="Datum RDW gekeurd"
                            v-model="form.datum_rdw_keuring"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <div class="display-1">Staat voertuig</div>
                    </v-col>
                </v-row>
                <transition name="slide-fade" mode="out-in">
                    <v-row key="1" v-if="!staatVoertuigToggle">
                        <v-col cols="12" md="4">
                            <v-btn @click="staatVoertuigToggle = true" color="warning" block>Toon velden</v-btn>
                        </v-col>
                    </v-row>
                    <v-row key="2" v-else>
                        <v-col cols="12">
                            <v-select id="input-23" outlined label="Staat wielophanging"
                                :items="staat_voertuig.wielophanging" v-model="form.staat.wielophanging">
                            </v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-select id="input-24" outlined label="Velgen/banden" :items="staat_voertuig.velgen_banden"
                                v-model="form.staat.velgen_banden">
                            </v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-select id="input-25" outlined label="Stuurinrichting" :items="staat_voertuig.stuurinrichting"
                                v-model="form.staat.stuurinrichting">
                            </v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-select outlined label="Remmen" :items="staat_voertuig.remmen" v-model="form.staat.remmen">
                            </v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-select id="input-26" outlined label="Versnellingsbak aandrijving"
                                :items="staat_voertuig.versnellingsbak" v-model="form.staat.versnellingsbak">
                            </v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-select id="input-27" outlined label="Elektrische installatie"
                                :items="staat_voertuig.elektrische_installatie"
                                v-model="form.staat.elektrische_installatie">
                            </v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-select id="input-28" outlined label="Carroserie staat" :items="staat_voertuig.carrosserie"
                                v-model="form.staat.carrosserie">
                            </v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-select id="input-29" outlined label="Interieur" :items="staat_voertuig.interieur"
                                v-model="form.staat.interieur">
                            </v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-select id="input-30" outlined label="Motor" :items="staat_voertuig.motor"
                                v-model="form.staat.motor">
                            </v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-select id="input-31" outlined label="Algehele staat" :items="staat_voertuig.algehele_staat"
                                v-model="form.staat.algehele_staat">
                            </v-select>
                        </v-col>
                    </v-row>
                </transition>
                <v-row>
                    <v-col cols="12">
                        <p class="display-1">Was dit een huurauto?</p>
                        <v-radio-group id="radio-rental" v-model="form.rental" row>
                            <v-radio id="radio-rental-ja" value="ja" label="Ja"> </v-radio>
                            <v-radio id="radio-rental-nee" value="nee" label="Nee"> </v-radio>
                        </v-radio-group>
                        <transition name="slide-fade">
                            <span v-if="form.rental === 'ja'">
                                De eerste foto van de auto (aangegeven met een rode rand) zal gebruikt worden als bewijs
                                dat dit een huurauto was.
                            </span>
                        </transition>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <p class="display-1">
                            Afbeeldingen
                        </p>
                        <v-btn color="success" @click="onButtonClick('fotoUploader')" block>
                            <v-icon> mdi-cloud-upload-outline </v-icon>&nbsp; Upload foto's
                        </v-btn>
                        <input id="fileinput-foto" ref="fotoUploader" type="file" accept="image/*" multiple class="d-none"
                            @change="onPhotosUpload($event)" />
                        <v-btn class="mt-2" color="warning" small @click="alleFotosToggle = !alleFotosToggle">
                            {{ alleFotosToggle ? "Laat alleen 6 foto's zien" : "Laat alle foto's zien" }}
                        </v-btn>
                        <v-btn class="mt-2 d-block" color="warning" small @click="clearPhotos">
                            Leeg foto's
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="8">
                        <transition-group name="slide-fade" tag="div" mode="out-in">
                            <div v-for="(foto, index) in fotosPreview" :key="foto.url" class="foto-preview-div">
                                <v-img class="hover-delete foto-preview" :src="foto.url" @click="deletePhoto(index)"
                                    :class="form.rental === 'ja' && index === 0 ? 'foto-highlight' : ''" />
                            </div>
                        </transition-group>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="9" md="4">
                        <p class="display-1">
                            VWE rapport
                        </p>
                        <v-btn color="success" @click="onButtonClick('vweUploader')" block>
                            <v-icon> mdi-cloud-upload-outline </v-icon>&nbsp; Upload VWE rapport
                        </v-btn>
                        <input id="fileinput-vwe" ref="vweUploader" type="file" accept="application/pdf" class="d-none"
                            @change="onDocumentUpload($event, 'vwe')" />
                    </v-col>
                    <v-col cols="3" md="8" class="d-flex align-end">
                        <v-icon v-if="form.vwe" color="green" x-large>
                            mdi-check-circle
                        </v-icon>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <p class="display-1">
                            Gaspedaal
                        </p>
                        <v-btn color="accent" @click="onButtonClick('gaspedaalUploader')" block>
                            <v-icon> mdi-cloud-upload-outline </v-icon>&nbsp; Upload gaspedaal foto
                        </v-btn>
                        <input id="fileinput-2" ref="gaspedaalUploader" type="file" accept="image/*" class="d-none"
                            @change="onPhotoUpload($event, 'gaspedaal')" />
                        <v-btn class="mt-2" color="accent" small @click="openGaspedaal" :disabled="gaspedaalLoading"
                            style="width: 180px">
                            <span v-if="!gaspedaalLoading"> <v-icon small>mdi-tab-plus</v-icon> Open gaspedaal </span>
                            <v-progress-circular :size="20" indeterminate color="primary" v-else></v-progress-circular>
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="8">
                        <transition name="slide-fade" mode="out-in">
                            <v-img v-if="form.gaspedaal" style="display: inline-block;" class="ma-2"
                                :key="form.gaspedaal.url" :src="form.gaspedaal.url" height="160" width="300">
                            </v-img>
                        </transition>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="9" md="4">
                        <p class="display-1">
                            Koerslijst<br />
                            <span class="caption">(wordt uit aangifteformulier gehaald als niet ingevuld)</span>
                        </p>
                        <v-btn color="accent" style="height: 36px !important" @click="onButtonClick('koerslijstUploader')"
                            block>
                            <v-icon> mdi-cloud-upload-outline </v-icon>&nbsp; Upload koerslijst
                        </v-btn>
                        <v-btn v-if="form.koerslijst" class="mt-2 justify" color="accent" small
                            @click="clearFormValue('koerslijst')">
                            Verwijder koerslijst
                        </v-btn>
                        <input id="fileinput-koerslijst" ref="koerslijstUploader" type="file" accept="application/pdf"
                            class="d-none" @change="onDocumentUpload($event, 'koerslijst')" />
                    </v-col>
                    <v-col cols="3" md="8" class="d-flex align-end">
                        <v-icon v-if="form.koerslijst" color="green" x-large>
                            mdi-check-circle
                        </v-icon>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6">
                        <span class="title">Met of zonder art.8</span>
                        <v-radio-group id="radio-art8" v-model="form.art8" row>
                            <v-radio id="radio-art8-zonder" value="zonder" label="Zonder artikel 8"> </v-radio>
                            <v-radio id="radio-art8-met" value="met" label="
                                Met artikel 8">
                            </v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="6">
                        <v-btn id="save-form-button" block color="primary" class="ma-2" @click="saveTaxatie(false)">
                            Opslaan
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-btn id="save-concept-button" block color="secondary" class="ma-2" @click="saveTaxatie(true)">
                            Bewaar concept
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
            <sending-component v-if="isSending" />
            <div v-if="isLoading" class="mx-auto mt-8" style="width: fit-content;">
                <v-progress-circular :size="100" indeterminate color="primary"></v-progress-circular>
            </div>
        </v-container>
    </v-form>
</template>

<script>
import axios from '../../axios';
import SendingComponent from '../Shared/SendingComponent.vue';

const DATUM_REGEX = /^[0-9]{1,2}-[0-9]{1,2}-[0-9]{4}$/;

export default {
    data() {
        return {
            staatVoertuigToggle: false,
            isSending: false,
            lastTaxatie: {},
            fotos: [],
            rdw_gekeurd: 'ja',
            form: {
                fotos: [],
                handelswaarde: '',
                schade_bedrag: '',
                art8: 'zonder',
                rental: 'nee',
                doel_taxatie: 'Waardebepaling t.b.v. import/BPM inclusief BPM',
                reden_taxatie:
                    'Betreft een voertuig met meer dan normale gebruikersschade, niet zijnde een schadevoertuig als bedoeld in artikel 1, eerste lid, onderdeel van de wegenverkeerswet 1994.',
                staat: {
                    wielophanging:
                        'De wielophanging verkeert voor zover waarneembaar in een normale staat. Geen afwijkingen waargenomen.',
                    velgen_banden:
                        'De stalen velgen/banden set verkeert in een gebruikelijke staat. Geen afwijkingen/schades waargenomen.',
                    stuurinrichting:
                        'De stuurinrichting verkeert in een gebruikelijke staat. Geen afwijkingen/schades waargenomen.',
                    remmen:
                        'De reminrichting verkeert in een gebruikelijke staat. Geen afwijkingen/schades waargenomen.',
                    versnellingsbak:
                        'De automatische versnellingsbak en de aandrijving verkeren voor zover waarneembaar in een redelijke staat. Geen afwijking/schades waargenomen.',
                    elektrische_installatie:
                        'De elektrische installatie verkeert voor zover waarneembaar in een gebruikelijke staat. Geen afwijkingen/schades waargenomen.',
                    carrosserie:
                        'De carrosserie verkeert in een beschadigde staat.  Zie bijlage SilverDat schadecalculatie.',
                    interieur: 'Het interieur verkeert in een goede staat. Normale sporen van gebruik waarneembaar.',
                    motor:
                        'De motor verkeert zover waarneembaar in een goed werkend staat. Geen afwijkingen/schades waargenomen.',
                    algehele_staat: 'Deze auto verkeert behoudens de schade in een normaal gebruikte staat.'
                },
                soort_voertuig: 'Personenauto',
                transmissie: 'Automaat',
                carroserie: '5-drs Hatchback',
                brandstof: 'Benzine'
            },
            customers: [],
            auto_merken: [
                'Audi',
                'BMW',
                'Citroën',
                'Ferrari',
                'Ford',
                'Hyundai',
                'Lamborghini',
                'Land Rover',
                'Lexus',
                'Mercedes-Benz',
                'Nissan',
                'Porsche',
                'Renault',
                'Rolls-Royce',
                'Seat',
                'Skoda',
                'Volkswagen'
            ],
            soorten: ['Personenauto', 'Bedrijfswagen'],
            transmissies: ['Automaat', 'Handgeschakeld'],
            carroseries: [
                '5-drs Hatchback',
                '3-drs Hatchback',
                '5-drs SUV',
                '0-drs Chassis',
                '4-drs Sedan',
                '2-drs Softtop',
                '5-drs MPV',
                '5-drs combi',
                '5-drs bus'
            ],
            brandstoffen: [
                'Benzine',
                'Diesel',
                'Elektrisch',
                'Hybride (benzine/elektra)',
                'Hybride (diesel/elektra)',
                'Hybride'
            ],
            staat_voertuig: {
                wielophanging: [
                    'De wielophanging verkeert voor zover waarneembaar in een normale staat. Geen afwijkingen waargenomen.',
                    'De wielophanging verkeert in een beschadigde staat. Zie bijlage SilverDat schadecalculatie.'
                ],
                velgen_banden: [
                    'De stalen velgen/banden set verkeert in een gebruikelijke staat. Geen afwijkingen/schades waargenomen.',
                    'De stalen velgen/banden set verkeert in een beschadigde staat. Zie bijlage SilverDat schadecalculatie.'
                ],
                stuurinrichting: [
                    'De stuurinrichting verkeert in een gebruikelijke staat. Geen afwijkingen/schades waargenomen.',
                    'De stuurinrichting verkeert in een beschadigde staat. Zie bijlage SilverDat schadecalculatie.'
                ],
                remmen: [
                    'De reminrichting verkeert in een gebruikelijke staat. Geen afwijkingen/schades waargenomen.',
                    'De reminrichting verkeert in een beschadigde staat.  Zie bijlage SilverDat schadecalculatie.'
                ],
                versnellingsbak: [
                    'De automatische versnellingsbak en de aandrijving verkeren voor zover waarneembaar in een redelijke staat. Geen afwijking/schades waargenomen.',
                    'De automatische versnellingsbak en de aandrijving verkeert in een beschadigde staat. Zie bijlage SilverDat schadecalculatie.',
                    'De handgeschakelde versnellingsbak en de aandrijving verkeren voor zover waarneembaar in een redelijke staat. Geen afwijking/schades waargenomen.',
                    'De handgeschakelde versnellingsbak en de aandrijving verkeert in een beschadigde staat. Zie bijlage SilverDat schadecalculatie.'
                ],
                elektrische_installatie: [
                    'De elektrische installatie verkeert voor zover waarneembaar in een gebruikelijke staat. Geen afwijkingen/schades waargenomen.',
                    'De elektrische installatie verkeert in een beschadigde staat. Zie bijlage SilverDat schadecalculatie.'
                ],
                carrosserie: [
                    'De carrosserie verkeert in een goede staat.',
                    'De carrosserie verkeert in een beschadigde staat.  Zie bijlage SilverDat schadecalculatie.'
                ],
                interieur: [
                    'Het interieur verkeert in een goede staat. Normale sporen van gebruik waarneembaar.',
                    'Het interieur verkeert in een beschadigde staat. Zie bijlage SilverDat schadecalculatie.'
                ],
                motor: [
                    'De motor verkeert zover waarneembaar in een goed werkend staat. Geen afwijkingen/schades waargenomen.',
                    'De motor verkeert zover in een beschadigde staat.  Zie bijlage SilverDat schadecalculatie.'
                ],
                algehele_staat: [
                    'Deze auto verkeert behoudens de schade in een normaal gebruikte staat.',
                    'Deze motor verkeert behoudens de schade in een normaal gebruikte staat.',
                    'Deze auto verkeert behoudens de schade in een redelijk staat. Er is gekozen voor een 72% taxatie in plaats  van een 31% taxatie omdat het geen total loss voertuig betreft en er sprake is van een lage kilometerstand.',
                    'Deze motor verkeert behoudens de schade in een redelijk staat. Er is gekozen voor een 72% taxatie in plaats  van een 31% taxatie omdat het geen total loss voertuig betreft en er sprake is van een lage kilometerstand.'
                ]
            },
            alleFotosToggle: false,
            gaspedaalLoading: false,
            rules: {
                required: value => !!value || 'Required.',
                isKlant: value => this.customers.some(klant => klant.naam === value) || 'Klant bestaat niet.'
            },
            isLoading: false
        };
    },
    async mounted() {
        try {
            this.isLoading = true;

            if (this.$route.params.id) {
                this.editToggle = true;

                const id = this.$route.params.id;
                const { data: form } = await axios.get(`/taxaties/${id}`);
                this.form = {
                    ...form,
                    klant: form.klant.naam,
                    gaspedaal:
                        form.gaspedaal !== ''
                            ? { url: `${axios.defaults.baseURL}/fotos/${form.gaspedaal}` }
                            : form.gaspedaal,
                    fotos:
                        form.fotos && form.fotos.length > 0
                            ? form.fotos.map(url => ({ url: `${axios.defaults.baseURL}/fotos/${url}` }))
                            : []
                };
                this.fotos = this.form.fotos;

                console.log('Info from GET', this.form);
            } else {
                this.form.datum_taxatie;
                this.form.rapportnummer = this.curDate;
                this.form.datum_taxatie = this.curDate;
                this.form.datum_rdw_keuring = '';

                this.lastTaxatie = (await axios.get('/taxaties/last')).data;

                const dossiernummer = String(+this.lastTaxatie.dossiernummer + 1);
                const zeros = 6 - dossiernummer.length;
                this.form.dossiernummer = `${'0'.repeat(zeros)}${dossiernummer}`;

                this.$forceUpdate();
            }

            this.customers = (await axios.get('/users')).data;
        } catch (e) {
            console.error(e);
        } finally {
            this.isLoading = false;
        }
    },
    computed: {
        fotosPreview() {
            return this.fotos.length > 0 ? (this.alleFotosToggle ? this.fotos : this.fotos.slice(0, 5)) : [];
        },
        curDate() {
            const date = new Date();
            return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
        }
    },
    methods: {
        async saveTaxatie(concept = false) {
            if (this.validateForm() && !concept) {
                alert('Niet alle gegevens ingevuld!');
                return;
            }
            if (this.validateTijdAanvangEnVertrek()) {
                alert('Vul een (correcte) tijd aanvang en tijd vertrek in.');
                return;
            }
            if (this.validateRDWKeuringDatum()) {
                alert('Vul een (correcte) datum in voor de RDW keuring.');
                return;
            }
            try {
                this.isSending = true;

                const postData = {
                    ...this.form,
                    klant: this.customers.find(customer => customer.naam === this.form.klant)._id,
                    kleur: this.form.kleur || 'N.V.T.',
                    rdw_gekeurd: this.rdw_gekeurd === 'ja' ? this.form.datum_rdw_keuring : this.curDate
                };

                console.log('Post data: ', postData);

                if (postData._id) {
                    if (concept) {
                        delete postData._id;
                        await axios.put(`/taxaties/concept/${this.form._id}`, postData);
                    } else {
                        delete postData._id;
                        await axios.put(`/taxaties/${this.form._id}`, postData);
                    }
                } else {
                    if (concept) {
                        await axios.post('/taxaties/concept', postData);
                    } else {
                        await axios.post('/taxaties', postData);
                    }
                }
                this.$router.replace('/taxaties/lijst');
            } catch (error) {
                console.error(error);
                this.isSending = false;
                alert('Er ging iets fout, heeft u alle gegevens ingevuld?');
            }
        },
        onButtonClick(ref) {
            this.$refs[ref].click();
        },
        async onPhotosUpload(e) {
            const nieuweFotos = await Promise.all(
                Object.values(e.target.files).map(
                    async foto =>
                        new Promise(resolve => {
                            const FR = new FileReader();

                            FR.onload = async event => {
                                resolve({
                                    foto,
                                    url: URL.createObjectURL(foto),
                                    base64: await this.compressFile(event.target.result)
                                });
                            };

                            FR.readAsDataURL(foto);
                        })
                )
            );
            this.fotos = [...nieuweFotos, ...this.fotos];
            this.form.fotos = this.fotos;
            this.$forceUpdate();
        },
        async onPhotoUpload(e, type) {
            const foto = e.target.files[0];

            this.form[type] = await new Promise(resolve => {
                const FR = new FileReader();

                FR.onload = async event => {
                    resolve({
                        foto,
                        url: URL.createObjectURL(foto),
                        base64: await this.compressFile(event.target.result, 1200)
                    });
                };

                FR.readAsDataURL(foto);
            });
            this.$forceUpdate();
        },
        async onDocumentUpload(e, type) {
            const FR = new FileReader();

            FR.onload = async event => {
                this.form[type] = event.target.result;
                this.$forceUpdate();
            };

            FR.readAsDataURL(e.target.files[0]);
        },
        compressFile(src, size = 600) {
            return new Promise(resolve => {
                // Create image
                const image = document.createElement('img');

                const onLoadFn = () => {
                    // Compress image with canvas
                    const compressedSize = size;

                    const aspectRatio = image.width / image.height;

                    const cv = document.createElement('canvas');

                    cv.height = compressedSize;
                    cv.width = compressedSize * aspectRatio;

                    const ctx = cv.getContext('2d');

                    ctx.drawImage(image, 0, 0, compressedSize * aspectRatio, compressedSize);
                    const compressedUrl = cv.toDataURL('image/jpeg', 1);

                    // Clean up
                    removeEventListener('load', onLoadFn);

                    // Return value
                    resolve(compressedUrl);
                };

                // Set function to respond on load
                image.addEventListener('load', onLoadFn);

                // Set source
                image.src = src;
            });
        },
        async openGaspedaal() {
            if (this.form.chassisnummer) {
                this.gaspedaalLoading = true;
                const { data } = await axios.get(`/taxaties/gaspedaal/${this.form.chassisnummer}`);
                if (data.url) {
                    window.open(data.url, '_blank').focus();
                }
                this.gaspedaalLoading = false;
            } else {
                alert('Vul eerst chassisnummer!');
            }
        },
        deletePhoto(index) {
            this.fotos.splice(index, 1);
        },
        goBack() {
            this.$router.push('/taxaties/lijst');
        },
        goToNew() {
            this.$router.push('/taxaties/maak/nieuw');
        },
        validateForm() {
            if (
                this.form &&
                this.form.klant &&
                this.form.handelswaarde &&
                this.form.schade_bedrag &&
                this.form.datum_taxatie &&
                DATUM_REGEX.test(this.form.datum_taxatie) &&
                this.form.dossiernummer &&
                this.form.chassisnummer &&
                this.form.chassisnummer.length === 17 &&
                this.form.rapportnummer &&
                this.form.fotos.length > 0 &&
                // this.form.gaspedaal &&
                this.form.vwe
            ) {
                return false;
            }
            return true;
        },
        validateTijdAanvangEnVertrek() {
            const tijdRegex = /^[0-9]{1,2}:[0-9]{2}$/;
            if (
                this.form &&
                this.form.tijd_aanvang &&
                this.form.tijd_vertrek &&
                tijdRegex.test(this.form.tijd_aanvang) &&
                tijdRegex.test(this.form.tijd_vertrek)
            ) {
                return false;
            }
            return true;
        },
        validateRDWKeuringDatum() {
            if (this.form && this.form.datum_rdw_keuring && DATUM_REGEX.test(this.form.datum_rdw_keuring)) {
                return false;
            }
            return true;
        },
        clearFormValue(val) {
            this.form[val] = null;
            this.$forceUpdate();
        },
        clearPhotos() {
            this.form = { ...this.form, fotos: [] };
            this.fotos = [];
        }
    },
    components: {
        SendingComponent
    },
    metaInfo: {
        title: 'Taxatie formulier'
    }
};
</script>

<style scoped>
.hover-delete {
    opacity: 1;
}

.hover-delete:hover {
    opacity: 0.2;
    transition: 0.2s;
    filter: hue-rotate(90deg);
}

.foto-preview {
    display: inline-block;
    width: 18%;
    margin: 1%;
    max-height: 200px;
}

.foto-highlight {
    border: 2px solid red;
}

.foto-preview-div {
    display: inline;
}
</style>
